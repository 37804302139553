<template>
  <div class="container_margin">
    <div class="row">
      <div class="col">
        <h5><b>Compose</b></h5>
      </div>

      <div v-if="searched_data.length !== 0 && rows > 10" class="col-5">
        <b-pagination
          class="float-right align-self-center mb-0"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          last-number
        ></b-pagination>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <div class="card border shadow-sm p-2 table-card">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" width="8%">{{ "ID" }}</th>
                <th scope="col" width="32%">{{ "Compose Type" }}</th>
                <th scope="col" width="15%" class="text-center">
                  {{ "Jurisdiction" }}
                </th>
                <th
                  scope="col"
                  width="15%"
                  class="text-center"
                  style="padding-right: 15px"
                >
                  {{ "Created Date" }}
                </th>
                <th
                  scope="col"
                  width="15%"
                  class="text-center"
                  style="padding-right: 15px"
                >
                  {{ "Uploaded By" }}
                </th>
                <th
                  scope="col"
                  width="20%"
                  class="text-center"
                  style="padding-right: 25px"
                >
                  {{ "Action" }}
                </th>
              </tr>
            </thead>

            <tbody>
              <PageLoader
                v-if="pageLoading"
                :loading="pageLoading"
              />

              <div
                v-else-if="searched_data.length === 0"
                class="container_margin"
              >
                <div class="mb-2 m-auto" style="width: 200px">
                  <img src="@/assets/noData.png" alt="No Data Image" />
                </div>

                <p class="text-center text-blue">
                  <b>{{ "No Data, Kindly compose a letter" }}</b>
                </p>
              </div>

              <tr v-else :key="index" v-for="(item, index) in searched_data">
                <td width="8%"><p>{{ item.id }}</p></td>
                <td width="32%">
                  <p v-if="item.letter_type === 'custom'">
                    {{ item.letter_type | letterType }}
                  </p>
                  <p v-else>
                    {{ item.letter_type | letterType }} ({{
                      (item.info?.isCM === 'creative_media'
                        ? item.info.isCM
                        : item.compose_type) | composeType
                    }})
                  </p>
                </td>
                <td width="15%" class="text-center">
                  <p>
                    {{ item.letter_type === 'custom'
                      ? "N/A" : item.info?.jurisdiction }}
                  </p>
                </td>
                <td width="15%" class="text-center">
                  <p class="text-muted">
                    {{ item.created_at | formatDate }}
                  </p>
                </td>
                <td width="15%" class="text-center">
                  <p class="text-muted font-weight-bold">
                    {{ item.creator.name | username }}
                  </p>
                </td>
                <td width="20%" class="text-center">
                  <button
                    v-if="item.completedPerCentage >= 100"
                    class="btn btn-primary"
                    @click="openCase(item.id, item)"
                  >
                    {{ "Open" }}
                  </button>

                  <button
                    @click="openCase(item.id, item)"
                    v-else
                    class="btn btn-warning-outlined h-100"
                  >
                    <span><b>Composing: </b></span>
                    <span class="no-wrap">
                      {{ Math.round(item.completedPerCentage) }} %
                      <b-spinner label="Spinning" class="spinner" />
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div style="height: 2.5rem;">
      <b-pagination
        v-if="searched_data.length !== 0 && rows > 10"
        class="float-right mt-3"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        last-number
      ></b-pagination>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import LegalWriter from "@/store/LegalWriter.js";
import { loopUpdate } from "../../store/utils";
export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      rows: 0,
      perPage: 10,
      currentPage: 1,
      pageLoading: true,
      uploading_percentage: 10,
      file_in_upload: false,
      page: 0,
      search: "", // Input for the search query
      chosenType: "", // Selected type option
      filter: "", // Input for the filter query
      chosenJurisdiction: "", // Selected jurisdiction option
      types: [], // Array of available types
      jurisdictions: [], // Array of available jurisdictions
      status: "not_accepted",
      options_export: [
        { name: "PDF", icon: "picture_as_pdf" },
        { name: "DOCUMENT", icon: "description" },
      ],
      chosenExports: [],
      searched_data: [], // Array to hold the search results

      loop: false,
    };
  },

  methods: {
    openCase(data, category) {
      this.$router.push({
        name: "LegalWriterEdit",
        params: {
          id: data,
        },
        query: {
          category: this.$options.filters.composeType(category.compose_type),
        },
      });
    },

    clearSearch() {
      this.search = "";
      this.chosenType = "";
      this.filter = "";
      this.chosenJurisdiction = "";
      this.searched_data = [];
    },

    loadData() {
      const me = this;
      function processData(data) {
        const DATA = data.data.data;
        const insert = me.searched_data.length > 0;
        me.pageLoading = false;
        me.rows = DATA.total;
        DATA.data.forEach(e => {
          const ID = me.searched_data.findIndex(old => old.id === e.id);

          if (e.statement) {
            e.statement = e.statement.replace(/<[^>]*>/g, "");
          }

          if (ID > -1) {
            me.$set(me.searched_data, ID, e);
          } else if (insert) {
            me.searched_data.unshift(e);
          } else {
            me.searched_data.push(e)
          }

          if (e.completedPerCentage < 100) me.loop = true;
        })
      }

      loopUpdate(
        this,
        LegalWriter.GetLegalWriter,
        `?type=${this.$route.query.category}&page=${this.currentPage}`,
        processData
      )
    },
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage != this.$route.query.page) {
        this.pageLoading = true;
        this.searched_data = [];

        this.$router.push({
          query: { page: newPage, category: this.$route.query.category },
        });
        clearTimeout(this.loop);
        this.loadData();
      }
    },
  },
  created() {
    const page = this.$route.query.page;
    if (page) {
      this.currentPage = parseInt(page, 10);
      this.searched_data = [];
    }
    this.loadData();
  },

  destroyed() {
    clearTimeout(this.loop);
  },
};
</script>
<style scoped>
/* table */
.table {
  border: none;
  background: white;
  table-layout: fixed;
}

tbody {
  display: block;
  max-height: auto;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}

.container_margin {
  padding: 2rem;
}

.spinner {
  color: #f08000 !important;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}
</style>
